import React, { useEffect, useRef } from "react";
import { useRouter } from "next/router";
import SwiperCore, { Autoplay, SwiperOptions } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import Image from "next/image";
import { endpoints } from "config";
import st from "./st.module.scss";
import { BRANDS } from "constants/brands";
import { getCloudflareLoader } from "@pepdirect/helpers/cloudflareImages";

/*
  Duplicating brands since otherwise it bugs :)
  https://github.com/nolimits4web/swiper/issues/2942#issuecomment-555365102
*/
const brandsDuplicated = BRANDS.concat(BRANDS);
SwiperCore.use([Autoplay]);

export function HeroCarousel(): JSX.Element {
  /* We need this magic to "update" swiper after routing happens, otherwise swiper appears scrolled */
  const swiperReference = useRef<SwiperCore | null>(null);
  const router = useRouter();

  useEffect(() => {
    const updateSwiper = () => {
      if (swiperReference.current) {
        swiperReference.current.update();
      }
    };
    router.events.on("routeChangeComplete", updateSwiper);
    return () => {
      router.events.off("routeChangeComplete", updateSwiper);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const options: SwiperOptions = {
    slidesPerView: "auto" as const,
    freeMode: true,
    speed: 6000,
    autoplay: {
      delay: 0,
      disableOnInteraction: false,
    },
    loop: true,
    loopedSlides: brandsDuplicated.length,
  };

  return (
    <div className={st.wrapper}>
      <Swiper
        {...options}
        className={st.swiperContainer}
        onSwiper={(current) => (swiperReference.current = current)}
      >
        {brandsDuplicated.map(({ name, logo }, index) => (
          <SwiperSlide key={`${name}-${index}`} className={st.slide}>
            <Image
              src={logo}
              width={80}
              height={80}
              loader={getCloudflareLoader(endpoints.cdn)}
              alt={name}
              style={{
                maxWidth: "100%",
                height: "auto",
              }}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
}
